@font-face{
  font-family:'drainer';
  src:url('../public/fonts/drainer.woff') format("woff");
}

.bgFilter {
  filter: invert(1) contrast(2) hue-rotate(288deg) brightness(2);
}

@keyframes fadeIn {
  0% {
      opacity: 0
  }

  to {
      opacity: 1
  }
}

.animate-fadeIn {
  animation: fadeIn .3s ease-in-out;
}

@keyframes text-slide-2 {
  0%,40% {
      transform: translateY(0)
  }

  50%,90% {
      transform: translateY(-50%)
  }

  to {
      transform: translateY(0)
  }
}

.animate-text-slide-2 {
  animation: text-slide-2 5s cubic-bezier(.83, 0, .17, 1) infinite;
}

.highlighted-text-shadow {
  text-shadow: 0 0.15ch 15px oklch(25% .2 320), 0 -2px 0 oklch(98% .05 320);
}

:root {
  --transition: 0.25s;
  --spark: 1.8s
}

.spcbtn {
  --cut: 0.1em;
  --active: 0;
  --bg: radial-gradient(40% 50% at center 100%,hsl(270 calc(var(--active) * 97%) 72%/var(--active)),transparent),radial-gradient(80% 100% at center 120%,hsl(260 calc(var(--active) * 97%) 70%/var(--active)),transparent),hsl(260 calc(var(--active) * 97%) calc((var(--active) * 44%) + 12%));
  background: var(--bg);
  font-size: 2rem;
  font-weight: 500;
  border: 0;
  cursor: pointer;
  padding: .6em 1.3em .3em;
  display: flex;
  align-items: center;
  gap: .25em;
  white-space: nowrap;
  border-radius: 100px;
  position: relative;
  box-shadow: 0 0 calc(var(--active) * 6em) calc(var(--active) * 3em) rgba(25,162,43,.75),0 .05em 0 0 hsl(260 calc(var(--active) * 97%) calc((var(--active) * 50%) + 30%)) inset,0 -.05em 0 0 hsl(260 calc(var(--active) * 97%) calc(var(--active) * 60%)) inset;
  transition: box-shadow var(--transition),scale var(--transition),background var(--transition);
  scale: calc(1 + (var(--active) * .1))
}

.sparkle-button button:active {
  scale: 1
}

.sparkle-button svg {
  overflow: visible!important
}

.sparkle path {
  color: hsl(0 0% calc((var(--active, 0) * 70%) + var(--base)));
  transform-box: fill-box;
  transform-origin: center;
  fill: currentColor;
  stroke: currentColor;
  animation-delay: calc((var(--transition) * 1.5) + (var(--delay) * 1s));
  animation-duration: .6s;
  transition: color var(--transition)
}

.sparkle-button button:is(:hover,:focus-visible) path {
  animation-name: bounce
}

@keyframes bounce {
  35%,65% {
      scale: var(--scale)
  }
}

.sparkle path:first-of-type {
  --scale: 0.5;
  --delay: 0.1;
  --base: 40%
}

.sparkle path:nth-of-type(2) {
  --scale: 1.5;
  --delay: 0.2;
  --base: 20%
}

.sparkle path:nth-of-type(3) {
  --scale: 2.5;
  --delay: 0.35;
  --base: 30%
}

.sparkle-button:before {
  content: "";
  inset: -.25em;
  z-index: -1;
  border: .25em solid rgba(15,124,32,.5);
  opacity: var(--active,0);
  transition: opacity var(--transition)
}

.spark,.sparkle-button:before {
  position: absolute;
  border-radius: 100px
}

.spark {
  inset: 0;
  rotate: 0deg;
  overflow: hidden;
  -webkit-mask: linear-gradient(#fff,transparent 50%);
  mask: linear-gradient(#fff,transparent 50%);
  animation: flip calc(var(--spark) * 2) infinite steps(2)
}

@keyframes flip {
  to {
      rotate: 1turn
  }
}

.spark:before {
  content: "";
  position: absolute;
  width: 200%;
  aspect-ratio: 1;
  top: 0;
  left: 50%;
  z-index: -1;
  translate: -50% -15%;
  rotate: 0;
  transform: rotate(-90deg);
  opacity: calc((var(--active)) + .4);
  background: conic-gradient(from 0deg,transparent 0 340deg,#fff 1turn);
  transition: opacity var(--transition);
  animation: rotate var(--spark) linear infinite both
}

.spark:after {
  content: ""
}

.backdrop,.spark:after {
  position: absolute;
  inset: var(--cut);
  border-radius: 100px
}

.backdrop {
  background: var(--bg);
  transition: background var(--transition)
}

@keyframes rotate {
  to {
      transform: rotate(90deg)
  }
}

@supports(selector(:has(:is(+ *)))) {
  body:has(button:is(:hover,:focus-visible)) {
      --active: 1;
      --play-state: running
  }

  .bodydrop {
      display: none
  }
}

button:is(:hover,:focus-visible)~:is(.bodydrop,.particle-pen) {
  --active: 1;
  --play-state: runnin
}

.bodydrop {
  background: hsl(260 calc(var(--active) * 97%) 6%);
  position: fixed;
  inset: 0;
  display: none;
  z-index: -100
}

button:is(:hover,:focus-visible) {
  --active: 1;
  --play-state: running
}

.sparkle-.spcbtn {
  position: relative
}

.pond-font {
  font-family: 'drainer';
  font-weight: 700;
  font-style: normal
}

.text {
  translate: 2% -6%;
  letter-spacing: .01ch;
  background: linear-gradient(90deg,hsl(0 0% calc((var(--active) * 100%) + 65%)),hsl(0 0% calc((var(--active) * 100%) + 26%)));
  -webkit-background-clip: text;
  color: #1acb34;
  transition: background var(--transition);
}

.font-roboto {
  font-family: Roboto,sans-serif
}